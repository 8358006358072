import { Package } from "../types";
import Markdown from "./Markdown";

interface PackagesProps {
  packages: Package[];
}

export default function Packages({ packages }: PackagesProps) {
  return (
    <div className="p-4 flex flex-wrap justify-center">
      {packages &&
        packages.map((p) => (
          <div className="mb-4 md:w-1/2" key={p.id}>
            <div
              className="p-4 mr-2 border-2 border-blue-800 rounded text-white min-h-full cursive"
              style={{
                background: "linear-gradient(#05668de6, #05668de6), url(/background.png)",
              }}
            >
              <div className="font-hairline text-5xl">{p.title}</div>
              <Markdown source={p.content} />
            </div>
          </div>
        ))}
    </div>
  );
}
