import Layout from "shared/Layout";
import { getSettings } from "lib/api";
import { HomePage, SiteSetting } from "types";
import Carousel from "shared/Carousel";
import Packages from "../shared/Packages";
import Species from "shared/Species";
import Scheduling from "shared/Scheduling";
import Sponsors from "shared/Sponsors";
import useCarousel from "hooks/useCarousel";
import Instagram from "shared/Instagram";
import Reviews from "shared/Reviews";

interface IndexPageProps {
  homepage: HomePage;
  siteSetting: SiteSetting;
}

export default function IndexPage({ homepage, siteSetting }: IndexPageProps) {
  const { images } = useCarousel(homepage.carousel);
  return (
    <Layout {...siteSetting} callToAction={homepage.callToActionPanel}>
      <Carousel images={images} />
      <Packages packages={homepage.package} />
      <Reviews reviews={homepage.reviews} />
      <Species species={homepage.targetedSpecies} />
      <Scheduling scheduling={homepage.scheduling} />
      <Instagram accessToken={siteSetting.instagramApiToken} total={6} />
      <Sponsors sponsors={homepage.sponsors} />
    </Layout>
  );
}

export async function getStaticProps() {
  const { homepage, siteSetting } = await getSettings();
  return {
    props: {
      homepage,
      siteSetting,
    },
  };
}
