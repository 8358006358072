import { Sponsor } from "types";
import LazyLoad from "react-lazyload";
import { Image } from "react-datocms";

interface SponsorsProps {
  sponsors: Sponsor[];
}

export default function Sponsors({ sponsors }: SponsorsProps) {
  if (sponsors && sponsors.length > 0) {
    return (
      <div className="p-4 bg-gray-700">
        <div className="text-5xl cursive text-white uppercase">Sponsors</div>
        <div className="flex items-center flex-wrap">
          {sponsors.map((sponsor) => (
            <div key={sponsor.id} className="w-1/3 md:w-1/6">
              <div className="p-6">
                <a target="_blank" href={sponsor.sponsorUrl}>
                  {sponsor.sponsorLogo.responsiveImage ? (
                    <Image
                      data={sponsor.sponsorLogo.responsiveImage}
                      pictureClassName="w-64 h-64"
                    />
                  ) : (
                    <img src={sponsor.sponsorLogo.url} alt={sponsor.sponsorLogo.alt} />
                  )}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
}
