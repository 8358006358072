import LazyLoad from "react-lazyload";

interface SchedulingProps {
  scheduling?: string;
}
export default function Scheduling({ scheduling = null }: SchedulingProps) {
  if (scheduling) {
    return (
      <LazyLoad>
        <div className="p-4 border-b">
          <div className="text-5xl cursive uppercase">Book a trip</div>
          <div dangerouslySetInnerHTML={{ __html: scheduling }} />
        </div>
      </LazyLoad>
    );
  }
  return null;
}
