import { useState } from "react";
import CarouselImage from "./Image";
import useInterval from "hooks/useInterval";

interface Image {
  src: any;
  thumb: string;
  children: React.ReactNode;
}

interface CarouselProps {
  images: Image[];
  delay?: number;
}

export default function Carousel({ images, delay = 5000 }: CarouselProps) {
  const [current, setCurrent] = useState(images && images.length > 0 ? images[0] : null);
  const [position, setPosition] = useState(0);
  useInterval(() => {
    if (current && position < images.length - 1) {
      setCurrent(images[position + 1]);
      setPosition(position + 1);
    } else {
      if (current) {
        setCurrent(images[0]);
        setPosition(0);
      }
    }
  }, delay);
  if (current) {
    return (
      <div className="mb-4">
        <CarouselImage
          src={current.src}
          thumb={current.thumb}
          children={current.children}
          delay={delay}
        />
      </div>
    );
  }
  return null;
}
