import { Species as SpeciesType } from "../types";

interface SpeciesProps {
  species: SpeciesType[];
}

export default function Species({ species }: SpeciesProps) {
  return (
    <div className="p-4 border-b">
      <div className="text-5xl cursive mb-4">Targeted Species</div>
      <div className="flex flex-wrap  list-disc">
        {species &&
          species.map((species) => (
            <div className="w-1/2 md:w-1/3 flex items-center md:justify-center" key={species.id}>
              <a
                className="font-bold text-sm md:text-xl text-blue-600 hover:text-blue-700 py-4"
                href={species.link}
              >
                {species.title}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
