import { Image } from "react-datocms";

export default function CarouselImage({ children, src, thumb, ...rest }) {
  return (
    <div className="relative">
      <Image data={src} style={{ width: "100%" }} />
      <div
        className="absolute"
        style={{ top: "50%", left: "20%", transform: "translate(-20%, -50%)" }}
      >
        {children}
      </div>
    </div>
  );
}
