import React from "react";
import { Carousel } from "types";
import Link from "next/link";

export default function useCarousel(items: Carousel[]) {
  const images = items.map((carouselItem) => ({
    src: carouselItem.carouselImage.responsiveImage,
    thumb: carouselItem.carouselImage.blurUpThumb,
    children: (
      <div className="w-full md:w-1/2 flex justify-center flex-col">
        <h1
          className="text-white text-2xl md:text-5xl font-hairline cursive mb-4"
          style={{ textShadow: "2px 2px 2px #222" }}
        >
          {carouselItem.title}
        </h1>
        {carouselItem.buttonUrl && carouselItem.buttonText && (
          <Link href={carouselItem.buttonUrl}>
            <a className="bg-green-700 hover:bg-green-800 text-white px-1 py-2 md:px-4 md:py-3 font-extrabold rounded shadow-xl uppercase text-center">
              {carouselItem.buttonText}
            </a>
          </Link>
        )}
      </div>
    ),
  }));
  return {
    images,
  };
}
