import { useEffect, useState } from "react";
import jsonp from "jsonp";
import LazyLoad from "react-lazyload";

export default function useInstagram(accessToken, total = 8) {
  const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}`;
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    jsonp(url, (err, { data }) => {
      if (err) {
        setLoading(false);
        setError(err);
      }
      setLoading(false);
      setPhotos(data);
    });
  }, [url]);
  const photoContent = (
    <div className="p-4">
      <h1 className="text-5xl cursive">Latest Media</h1>
      <hr />
      <div className={`flex flex-wrap ${loading && "justify-center"}`}>
        {photos &&
          !loading &&
          [...photos].splice(0, total).map((photo) => {
            return (
              <div className="w-1/2 md:w-1/6" key={photo.id}>
                <div className="p-1">
                  <a href={photo.link} target="_blank" aria-label={photo.caption.text}>
                    <LazyLoad height={192}>
                      <img
                        src={photo.images.low_resolution.url}
                        className="w-48 h-48"
                        alt={photo.caption.text}
                      />
                    </LazyLoad>
                  </a>
                </div>
              </div>
            );
          })}
        {loading && <div className="text-gray-600 text-center p-8">Loading Photos...</div>}
      </div>
    </div>
  );
  return {
    photos: photoContent,
  };
}
