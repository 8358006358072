import { Review } from "types";

export default function Reviews({ reviews }: { reviews: Review[] }) {
  return (
    <div className="p-4">
      <div className="my-4 text-5xl font-medium cursive">Recent Reviews</div>
      {reviews?.map((review) => (
        <div key={review.id} className="my-8 border-b py-4">
          <div className="text-xl font-thin mb-2">{review.text}</div>
          <div className="font-medium mb-2">- {review.name}</div>
        </div>
      ))}
    </div>
  );
}
